import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useWithdraw } from "queries/balance";

import SixtyButton from "components/SixtyButton";
import Input from "components/FormComponents/ControlledInput";

import styles from "./index.module.css";
import { formatCurrency } from "utils/common";
import { setToast } from "components/Toast";

const defaultValues = {
  amount: "",
};

const WithdrawBalanceForm = ({ handleClose, availableBalance }) => {
  const schema = yup.object().shape({
    amount: yup
      .number("Required")
      .max(availableBalance, "Enter value within your balance"),
  });
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { 
    mutateAsync: withdraw, 
    isSuccess: withdrawSuccess, 
    isLoading: withdrawLoading
   } = useWithdraw();

  const onSubmit = async (data) => {
    try {
      await withdraw(data);
    } catch (e) {
      handleClose();
    }
  };

  useEffect(() => {
    if (withdrawSuccess) {
      setToast("Withdraw successful");
      handleClose();
    }
  }, [handleClose, withdrawSuccess]);

  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.formContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <div className={styles.fieldHeader}>Available balances</div>
              <div className={styles.fieldValue}>
                {formatCurrency(availableBalance)}
              </div>
              <div>
                <Input
                  name="amount"
                  label="Amount"
                  type="number"
                  min={0}
                  step=".01"
                  error={formMethods?.errors?.amount}
                  // max={availableBalance}
                />
              </div>
            </div>
            <footer className={styles.footer}>
              <SixtyButton type="submit" className={styles.withdrawBtn} disabled={withdrawLoading}>
                Withdraw
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default WithdrawBalanceForm;
