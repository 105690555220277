import { useCallback, useMemo, useState } from "react";
import format from "date-fns/format";
import clsx from "clsx";
import { usePagination, useTable } from "react-table";
import {
  useExportCompanyBalances,
  useGetCompanyBalanceHistories,
} from "queries/balance";
import { getFormattedDateString } from "utils/date.util";

import Table from "components/SixtyTable";
import EmptyGrid from "components/EmptyGrid";

import { ReactComponent as IconCreditBalances } from "assets/icons/Icon-CreditBalance.svg";
import styles from "./index.module.css";
import { ButtonDatePicker } from "components/SixtyDatePicker";

// todo: Add filter buttons and functionalities

export default function RecentTransactions() {
  const [month, setMonth] = useState(new Date());

  // pagination
  // const [startFrom, setStart] = useState(0);
  // const limit = 20;
  const [filteredDays, setFilteredDays] = useState(null);

  const getFilters = useCallback(() => {
    return {
      // startFrom,
      // limit,
      // filteredDays: filteredDays ? `${filteredDays}` : null,
      reportDate: format(month, "MM/yyyy"),
    };
  }, [month]);
  const { data: historyData } = useGetCompanyBalanceHistories(getFilters());

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        style: { padding: "0px 16px" },
        collapse: true,
        Cell: ({ value, row }) => (
          <div>
            <div>{getFormattedDateString(value, "MMM d, y")}</div>
          </div>
        ),
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
        collapse: true,
      },
      {
        Header: "Description",
        accessor: "description",
        collapse: true,
      },
      {
        Header: "Amount",
        accessor: "amount",
        collapse: true,
        Cell: ({ value, row }) => (
          <div>
            <div
              className={clsx({
                [styles.credit]:
                  row?.values?.transactionType?.toLowerCase() !== "withdraw",
              })}
            >
              {row?.values?.transactionType?.toLowerCase() === "withdraw"
                ? "- RM" + -value
                : "+ RM" + value}
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        collapse: true,
      },
    ],
    []
  );

  const data = useMemo(
    () => (historyData?.result?.length > 0 ? historyData.result : []),
    [historyData]
  );

  const onLast30days = () => {
    setFilteredDays(30);
  };

  const { mutateAsync: exportBalances } = useExportCompanyBalances();
  const onExport = () => {
    exportBalances();
  };

  const tableInstance = useTable({ columns, data }, usePagination);

  return (
    <div>
      <div className={styles.transactionTableHeader}>
        <div>
          <span className={styles.gridHeader}>Recent transactions</span>
        </div>
        <div className={styles.ButtonsContainer}>
          <div>
            {/* <Button
              className={styles.addDiscountBtn}
              variant="secondary"
              onClick={onLast30days}
              roundedSquare
            >
              <span className={styles.calendarIcon}>
                <CalendarIcon />
              </span>
              Last 30 days
            </Button> */}
            <ButtonDatePicker
              value={month}
              maxDate={new Date()}
              handleChange={(date) => setMonth(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
          {/* <div>
            <Button
              className={styles.exportBtn}
              variant="secondary"
              label="Export"
              onClick={onExport}
            />
          </div> */}
        </div>
      </div>
      {data?.length > 0 ? (
        <Table
          instance={tableInstance}
          // totalItems={historyData?.totalRecords}
          // startFrom={startFrom}
          // limit={limit}
          // setStart={setStart}
        />
      ) : (
        <EmptyGrid
          Icon={IconCreditBalances}
          emptyMessage={
            "There is no recent transactions available for " +
            format(month, "MMM, y")
          }
        />
      )}
    </div>
  );
}
