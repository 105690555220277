import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { saveAs } from "file-saver";



export function useGetCompanyCreditBalance() {
  return useQuery(
    Endpoints.Balance.GetCompanyCreditBalance,
    () =>
      api
        .get(Endpoints.Balance.GetCompanyCreditBalance)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetCompanyCreditBalance: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetCompanyBalanceHistories(filter) {
  return useQuery(
    [Endpoints.Balance.GetCompanyBalanceHistories, filter],
    () =>
      api
        .post(Endpoints.Balance.GetCompanyBalanceHistories, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError(
          "useGetCompanyBalanceHistories: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useExportCompanyBalances() {
  return useMutation(
    () =>
      api
        .get(Endpoints.Balance.ExportCompanyBalances, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            ?.find((n) => n.includes("filename="))
            ?.replace("filename=", "")
            ?.trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
          return response;
        }),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useExportCompanyBalances: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useWithdraw() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Balance.Withdraw, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useWithdraw: useMutation: onError: error", error);
      },
    }
  );
}
