import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SixtyButton from "components/SixtyButton";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import {
  useGetCompanyBankAccount,
  useSetupCompanyBankAccount,
} from "queries/bankAccount";
import { setToast } from "components/Toast";

const defaultValues = {
  bankOwnerName: "",
  bankAccountNo: "",
  bankName: "",
};

const AddBankAccountForm = ({ update, handleClose }) => {
  const { data, isFetchedAfterMount, refetch } = useGetCompanyBankAccount();

  const { 
    mutateAsync: updateBank,
    isLoading: bankLoading 
  } = useSetupCompanyBankAccount();

  const schema = yup.object().shape({
    bankOwnerName: yup.string().required("Owner name is required"),
    bankAccountNo: yup.string().required("Account number is required"),
    bankName: yup.string().required("Bank name is required"),
  });

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (data && isFetchedAfterMount) reset(data);
  }, [reset, isFetchedAfterMount, data]);

  const onSubmit = async (data) => {
    const result = await updateBank({
      bankOwnerName: data.bankOwnerName,
      bankAccountNo: data.bankAccountNo,
      bankName: data.bankName,
    });
    if (result) {
      refetch();
      setToast("Updated bank account succesfully");
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <FormProvider {...formMethods}>
        <div className={styles.formContainer}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className={styles.form}>
              <div className={styles.fullName}>
                <Input
                  name="bankOwnerName"
                  label="Full name in the bank account"
                />
              </div>
              <div className={styles.accountNumber}>
                <Input name="bankAccountNo" label="Account number" />
              </div>
              <div>
                <Input name="bankName" label="Bank name" />
              </div>
              {/* <div>
                    <ChipInput
                        name="bankName"
                        label="Bank name"
                        lines={1}
                        defaultValue={[]}
                        options={dummyBankOptions}
                        showDropdownIcon
                        isMultiSelect={false}
                    />
                            </div> */}
            </div>
            <footer className={styles.footer}>
              <SixtyButton type="submit" className={styles.addAccountBtn} disabled={bankLoading}>
                {update ? "Update bank account" : "Add bank account"}
              </SixtyButton>
              <SixtyButton
                variant="secondary"
                className={styles.cancelButton}
                onClick={() => handleClose()}
              >
                Cancel
              </SixtyButton>
            </footer>
          </form>
        </div>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddBankAccountForm;
