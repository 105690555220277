import React, { useState, useRef } from "react";
import Button from "components/SixtyButton";
import styles from "./index.module.css";
import Modal from "components/Modal";
import { useHistory } from "react-router-dom";
import WithdrawBalanceForm from "../WithdrawBalanceForm";
import { formatCurrency } from "utils/common";

const BalanceDetails = ({ balance }) => {
  const [isWithdrawBalanceModalOpen, setWithdrawBalanceModalOpen] = useState(
    false
  );
  const history = useHistory();
  const balanceDetailRef = useRef();

  const Title = () => {
    return (
      <div className={styles.balanceDetailsTitleBar}>
        <div className={styles.modalTitle}>Withdraw from wallet balances</div>
      </div>
    );
  };

  const onBalanceWithdraw = () => {
    setWithdrawBalanceModalOpen(true);
  };

  const onBalanceWithdrawCancel = () => {
    setWithdrawBalanceModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.leftContainer}>
          <div className={styles.rowHeader}>Balance</div>
          <div className={styles.rowValue}>{formatCurrency(balance || 0)}</div>
        </div>
        <div className={styles.rightContainer}>
          <Button
            className={styles.inviteSellerBtn}
            label="Withdraw"
            disabled={!balance}
            onClick={onBalanceWithdraw}
          />
        </div>
      </div>
      {isWithdrawBalanceModalOpen && (
        <Modal
          isOpen={isWithdrawBalanceModalOpen}
          onAttemptClose={() => {
            history.push("/dashboard/walletbalances");
            setWithdrawBalanceModalOpen(false);
          }}
          modalRef={balanceDetailRef}
          Title={Title}
        >
          <WithdrawBalanceForm
            handleClose={onBalanceWithdrawCancel}
            availableBalance={balance}
          />
        </Modal>
      )}
    </div>
  );
};

export default BalanceDetails;
