import { queryClient } from "common/query";
import { useMutation, useQuery } from "react-query";
import api from "../common/axios";
import { Endpoints, unknown_Error } from "../common/constants";
import { reportError } from "../crashlytics";
import { setToast } from "components/Toast";
import { $CombinedState } from "@reduxjs/toolkit";
import { saveAs } from "file-saver";

export function useGetUgcOrders(filter) {
  return useQuery(
    [Endpoints.Admin.GetUgcOrders, filter],
    () =>
      api
        .post(Endpoints.Admin.GetUgcOrders, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetUgcOrders: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetUgcOrderStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetUgcOrderStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetUgcOrderStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetUgcOrderStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useExportCampaignInfluencers() {
  return useMutation(
    (filter) =>
      api
        .post(Endpoints.Admin.ExportCampaignInfluencers, filter, {
          responseType: "blob",
          "Accept-Encoding": "gzip, deflate, br",
        })
        .then((response) => {
          let filename = response.headers["content-disposition"]
            ?.split(";")
            .find((n) => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename || "data.xlsx");
        }),
    {
      onSuccess: (data) => {},
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError("useExportCampaignInfluencers: useMutation: onError: error", error);
      },
    }
  );
}

export function useGetBlogProductCount(id) {
  return useQuery(
    [Endpoints.Admin.GetBlogProductCount, id],
    () =>
      api
        .get(Endpoints.Admin.GetBlogProductCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetBlogProductCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetBlogDetailById(id) {
  return useQuery(
    [Endpoints.Admin.GetBlogDetailById, id],
    () =>
      api
        .get(Endpoints.Admin.GetBlogDetailById + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetBlogDetailById: useQuery: onError: error", error);
      },
    }
  );
}

export function useRemoveProductFromBlog() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Admin.RemoveProductFromBlog + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetBlogProducts);
        queryClient.refetchQueries(Endpoints.Admin.SearchBlogProducts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRemoveProductFromBlog: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useUpdateBlogProductRedirection() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateBlogProductRedirection, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateBlogProductRedirection: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetBlogProductDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetBlogProductDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetBlogProductDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetBlogProductDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddProductToBlog() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.AddProductToBlog, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Blog.GetBlogs);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useAddProductToBlog: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useSearchBlogProducts(filter) {
  return useQuery(
    [Endpoints.Admin.SearchBlogProducts, filter],
    () =>
      api
        .post(Endpoints.Admin.SearchBlogProducts, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useSearchBlogProducts: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetBlogProducts(filter) {
  return useQuery(
    [Endpoints.Admin.GetBlogProducts, filter],
    () =>
      api
        .post(Endpoints.Admin.GetBlogProducts, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetBlogProducts: useQuery: onError: error", error);
      },
    }
  );
}

export function useCreateBlog() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.CreateBlog, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Blog.GetBlogs);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useCreateBlog: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function usePublishOrUnpublishBlog() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.PublishOrUnpublishBlog + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Blog.GetBlogs);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "usePublishOrUnpublishBlog: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useUpdateBlog() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateBlog, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Blog.GetBlogs);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateBlog: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetBlogStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetBlogStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetBlogStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetBlogStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetProductPreview(id) {
  return useQuery(
    [Endpoints.Admin.GetProductPreview, id],
    () =>
      api
        .get(Endpoints.Admin.GetProductPreview + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetProductPreview: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddProductToCampaign() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.AddProductToCampaign, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.SearchCampaignProducts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useAddProductToCampaign: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useUpdateCampaignProductRedirection() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateCampaignProductRedirection, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateCampaignProductRedirection: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useRemoveProductFromCampaign() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.RemoveProductFromCampaign + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Campaign.GetCampaignProducts);
        queryClient.refetchQueries(Endpoints.Admin.SearchCampaignProducts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRemoveProductFromCampaign: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useSearchCampaignProducts(filter) {
  return useQuery(
    [Endpoints.Admin.SearchCampaignProducts, filter],
    () =>
      api
        .post(Endpoints.Admin.SearchCampaignProducts, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useSearchCampaignProducts: useQuery: onError: error", error);
      },
    }
  );
}

export function useInviteSeller() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.InviteSeller, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useInviteSeller: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useOverrideSellerCommission() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.OverrideSellerCommission, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useOverrideSellerCommission: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetRefundItemDetails(id) {
  return useQuery(
    [Endpoints.Admin.GetRefundItemDetails, id],
    () =>
      api
        .get(Endpoints.Admin.GetRefundItemDetails + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetRefundItemDetails: useQuery: onError: error", error);
      },
    }
  );
}

export function useMarkBuyerRefundComplete() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.MarkBuyerRefundComplete + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Order.GetBuyerRefundRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useMarkBuyerRefundComplete: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetPromotions(filter) {
  return useQuery(
    [Endpoints.Admin.GetPromotions, filter],
    () =>
      api
        .post(Endpoints.Admin.GetPromotions, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetPromotions: useQuery: onError: error", error);
      },
    }
  );
}

export function useDeletePromotion() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Admin.DeletePromotion + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDeletePromotion: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useCreatePromotion() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.CreatePromotion, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useCreatePromotion: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useUpdateSubscription() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateSubscription, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateSubscription: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetSubscriptionListing(filter) {
  return useQuery(
    [Endpoints.Admin.GetSubscriptionListing, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSubscriptionListing, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSubscriptionListing: useQuery: onError: error", error);
      },
    }
  );
}

export function useSentAdvertisement() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.SentAdvertisement, data)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useSentAdvertisement: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetSellerWallets(filter) {
  return useQuery(
    [Endpoints.Admin.GetSellerWallets, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSellerWallets, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerWallets: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerCreditHistories(filter) {
  return useQuery(
    [Endpoints.Admin.GetSellerCreditHistories, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSellerCreditHistories, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerCreditHistories: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerPayoutHistories(filter) {
  return useQuery(
    [Endpoints.Admin.GetSellerPayoutHistories, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSellerPayoutHistories, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerPayoutHistories: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerCreditBalances(code) {
  return useQuery(
    [Endpoints.Admin.GetSellerCreditBalances, code],
    () =>
      api
        .get(Endpoints.Admin.GetSellerCreditBalances + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerCreditBalances: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCreditDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetCreditDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetCreditDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCreditDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPayoutRecordCount(id) {
  return useQuery(
    [Endpoints.Admin.GetPayoutRecordCount, id],
    () =>
      api
        .get(Endpoints.Admin.GetPayoutRecordCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPayoutRecordCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerCreditCount(id) {
  return useQuery(
    [Endpoints.Admin.GetSellerCreditCount, id],
    () =>
      api
        .get(Endpoints.Admin.GetSellerCreditCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerCreditCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSocialMediaDropdown() {
  return useQuery(
    [Endpoints.Admin.GetSocialMediaDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetSocialMediaDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSocialMediaDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetFollowerGroupDropdown() {
  return useQuery(
    [Endpoints.Admin.GetFollowerGroupDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetFollowerGroupDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetFollowerGroupDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAudiencePreferenceDropdown() {
  return useQuery(
    [Endpoints.Admin.GetAudiencePreferenceDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetAudiencePreferenceDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetAudiencePreferenceDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAdminSideMenuCount() {
  return useQuery(
    [Endpoints.Admin.GetAdminSideMenuCount],
    () =>
      api
        .get(Endpoints.Admin.GetAdminSideMenuCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetAdminSideMenuCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignProductCount(id) {
  return useQuery(
    [Endpoints.Admin.GetCampaignProductCount, id],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignProductCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignProductCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignSellerRequestCount(id) {
  return useQuery(
    [Endpoints.Admin.GetCampaignSellerRequestCount, id],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignSellerRequestCount + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignSellerRequestCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAffiliateProgramStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetAffiliateProgramStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetAffiliateProgramStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetAffiliateProgramStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetVendorStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetVendorStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetVendorStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetVendorStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetSellerStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetSellerStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPayoutStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetPayoutStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetPayoutStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPayoutStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetVendorRefundStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetVendorRefundStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetVendorRefundStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetVendorRefundStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignStatusCount() {
  return useQuery(
    [Endpoints.Admin.GetCampaignStatusCount],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignStatusCount)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignStatusCount: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPublicCampaignRequests(filter) {
  return useQuery(
    [Endpoints.Admin.GetPublicCampaignRequests, filter],
    () =>
      api
        .post(Endpoints.Admin.GetPublicCampaignRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetPublicCampaignRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignSellerRequests(filter) {
  return useQuery(
    [Endpoints.Admin.GetCampaignSellerRequests, filter],
    () =>
      api
        .post(Endpoints.Admin.GetCampaignSellerRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaignSellerRequests: useQuery: onError: error", error);
      },
    }
  );
}

export function useRejectCampaignRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.RejectCampaignRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetPublicCampaignRequests);
        queryClient.refetchQueries(Endpoints.Admin.GetCampaignSellerRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRejectCampaignRequest: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useApproveCampaignRequest() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.ApproveCampaignRequest + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetPublicCampaignRequests);
        queryClient.refetchQueries(Endpoints.Admin.GetCampaignSellerRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useApproveCampaignRequest: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetCampaigns(filter) {
  return useQuery(
    [Endpoints.Admin.GetCampaigns, filter],
    () =>
      api
        .post(Endpoints.Admin.GetCampaigns, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetCampaigns: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetCampaignDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useCreateCampaign() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.CreateCampaign, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetCampaigns);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useCreateCampaign: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdateCampaign() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateCampaign, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetCampaigns);
        queryClient.invalidateQueries([Endpoints.Admin.GetCampaignDetail, data.id]);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateCampaign: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useGenerateCampaignPermalink() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.GenerateCampaignPermalink + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGenerateCampaignPermalink: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useDeleteCampaign() {
  return useMutation(
    (id) =>
      api
        .delete(Endpoints.Admin.DeleteCampaign + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetCampaigns);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useDeleteCampaign: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetCampaignRewardRequirementDropdown() {
  return useQuery(
    [Endpoints.Admin.GetCampaignRewardRequirementDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignRewardRequirementDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignRewardRequirementDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPromotionDropdown() {
  return useQuery(
    [Endpoints.Admin.GetPromotionDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetPromotionDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPromotionDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSubscriptionTypeDropdown() {
  return useQuery(
    [Endpoints.Admin.GetSubscriptionTypeDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetSubscriptionTypeDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSubscriptionTypeDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignPriceTypeDropdown() {
  return useQuery(
    [Endpoints.Admin.GetCampaignPriceTypeDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignPriceTypeDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignPriceTypeDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetCampaignStatusDropdown() {
  return useQuery(
    [Endpoints.Admin.GetCampaignStatusDropdown],
    () =>
      api
        .get(Endpoints.Admin.GetCampaignStatusDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetCampaignStatusDropdown: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerDropdown(data) {
  return useQuery([Endpoints.Admin.GetSellerDropdown, data], () =>
    api
      .post(Endpoints.Admin.GetSellerDropdown, data)
      .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetSellerDropdown: useQuery: onError: error", error);
        },
      }
  );
}

export function useGetVendorProductDropdown(data) {
  return useQuery([Endpoints.Admin.GetVendorProductDropdown, data], () =>
    api
      .post(Endpoints.Admin.GetVendorProductDropdown, data)
      .then((response) => response?.data?.result),
      {
        onError: (error) => {
          reportError("useGetVendorProductDropdown: useQuery: onError: error", error);
        },
      }
  );
}

export function useGetPayouts(filter) {
  return useQuery(
    [Endpoints.Admin.GetPayouts, filter],
    () =>
      api
        .post(Endpoints.Admin.GetPayouts, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetPayouts: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetPayoutDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetPayoutDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetPayoutDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetPayoutDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useIssuedPayout() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.IssuedPayout, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetPayouts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useIssuedPayout: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useIssuedCampaignPayout() {
  return useMutation(
    (data) =>
      api
        .post(Endpoints.Admin.IssuedCampaignPayout, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetPayouts);
        queryClient.refetchQueries(Endpoints.Posting.GetCampaignRewardRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useIssuedCampaignPayout: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useVoidPayout() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.VoidPayout, data)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetPayouts);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useVoidPayout: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetSellerVendorTraffic(code) {
  return useQuery(
    [Endpoints.Admin.GetSellerVendorTraffic, code],
    () =>
      api
        .get(Endpoints.Admin.GetSellerVendorTraffic + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerVendorTraffic: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerDetails(code) {
  return useQuery(
    [Endpoints.Admin.GetSellerDetails, code],
    () =>
      api
        .get(Endpoints.Admin.GetSellerDetails + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerDetails: useQuery: onError: error", error);
      },
    }
  );
}

export function useRestrictAccount() {
  return useMutation(
    (code) =>
      api
        .put(Endpoints.Admin.RestrictAccount + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRestrictAccount: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useUnlockAccountRestriction() {
  return useMutation(
    (code) =>
      api
        .put(Endpoints.Admin.UnlockAccountRestriction + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUnlockAccountRestriction: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useSuspendOrActivateSeller() {
  return useMutation(
    (code) =>
      api
        .put(Endpoints.Admin.SuspendOrActivateSeller + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellers);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useSuspendOrActivateSeller: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useGetSellerRegistrationDetail(code) {
  return useQuery(
    [Endpoints.Admin.GetSellerRegistration, code],
    () =>
      api
        .get(Endpoints.Admin.GetSellerRegistration + `/${code}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetSellerRegistrationDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useApproveSellerRegistration() {
  return useMutation(
    (code) =>
      api
        .put(Endpoints.Admin.ApproveSellerRegistration + `/${code}`)
        .then((response) => response?.data?.result),
      {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellerRegistrations);
      },
      onError: (error) => {
        console.log("Error");
        console.log(error);
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useApproveSellerRegistration: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useRejectSellerRegistration() {
  return useMutation(
    (code) =>
      api
        .put(Endpoints.Admin.RejectSellerRegistration + `/${code}`)
        .then((response) => response?.data?.result),
      {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetSellerRegistrations);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRejectSellerRegistration: useMutation: onError: error",
          error
        );
      }
    }
  );
}

export function useMarkVendorRefundComplete() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.MarkVendorRefundComplete + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetVendorRefunds);
      },
      onError: (error) => {
        reportError("MarkVendorRefundComplete: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetVendorRefunds(filter) {
  return useQuery(
    [Endpoints.Admin.GetVendorRefunds, filter],
    () =>
      api
        .post(Endpoints.Admin.GetVendorRefunds, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetVendorRefunds: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellerRegistrations(filter) {
  return useQuery(
    [Endpoints.Admin.GetSellerRegistrations, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSellerRegistrations, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellerRegistrations: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetSellers(filter) {
  return useQuery(
    [Endpoints.Admin.GetSellers, filter],
    () =>
      api
        .post(Endpoints.Admin.GetSellers, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetSellers: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetRejectedSellers(filter) {
  return useQuery(
    [Endpoints.Admin.GetRejectedSellers, filter],
    () =>
      api
        .post(Endpoints.Admin.GetRejectedSellers, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetRejectedSellers: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetVendorAffiliateAnalytics(filter) {
  return useQuery(
    [Endpoints.Admin.GetVendorAffiliateAnalytics, filter],
    () =>
      api
        .post(Endpoints.Admin.GetVendorAffiliateAnalytics, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetVendorAffiliateAnalytics: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetAffiliateProgramVendors(filter) {
  return useQuery(
    [Endpoints.Admin.GetAffiliateProgramVendors, filter],
    () =>
      api
        .post(Endpoints.Admin.GetAffiliateProgramVendors, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetAffiliateProgramVendors: useQuery: onError: error", error);
      },
    }
  );
}

export function useAddToAffiliateProgram() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.AddToAffiliateProgram + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetVendorDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useAddToAffiliateProgram: useMutation: onError: error",
          error
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(Endpoints.Admin.GetVendorDetail);
      },
    }
  );
}

export function useGenerateAffiliateProgramPermalink() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.GenerateAffiliateProgramPermalink + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useGenerateAffiliateProgramPermalink: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useApproveAccess() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.ApproveAccess + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetVendorDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useApproveAccess: useMutation: onError: error",
          error
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(Endpoints.Admin.GetVendorDetail);
      },
    }
  );
}

export function useRemoveFromAffiliateProgram() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.RemoveFromAffiliateProgram + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetVendorDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useRemoveFromAffiliateProgram: useMutation: onError: error",
          error
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(Endpoints.Admin.GetVendorDetail);
      },
    }
  );
}

export function useGetVendors(filter) {
  return useQuery(
    [Endpoints.Admin.GetVendors, filter],
    () =>
      api
        .post(Endpoints.Admin.GetVendors, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError("useGetVendors: useQuery: onError: error", error);
      },
    }
  );
}

export function useGetVendorDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetVendorDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetVendorDetail + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError("useGetVendorDetail: useQuery: onError: error", error);
      },
    }
  );
}

export function useSuspendOrActivateVendor() {
  return useMutation(
    (id) =>
      api
        .put(Endpoints.Admin.SuspendOrActivateVendor + `/${id}`)
        .then((response) => response?.data?.result),
    {
      onSuccess: () => {
        queryClient.refetchQueries(Endpoints.Admin.GetVendorDetail);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useSuspendOrActivateVendor: useMutation: onError: error",
          error
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(Endpoints.Admin.GetVendorDetail);
      },
    }
  );
}

export function useGetWithdrawalRequests(filter) {
  return useQuery(
    [Endpoints.Admin.GetWithdrawalRequests, filter],
    () =>
      api
        .post(Endpoints.Admin.GetWithdrawalRequests, filter)
        .then((response) => response?.data),
    {
      onError: (error) => {
        reportError(
          "useGetWithdrawalRequests: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useGetWithdrawalRequestDetail(id) {
  return useQuery(
    [Endpoints.Admin.GetWithdrawalRequestDetail, id],
    () =>
      api
        .get(Endpoints.Admin.GetWithdrawalRequestDetail + "/" + id)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "useGetWithdrawalRequestDetail: useQuery: onError: error",
          error
        );
      },
    }
  );
}

export function useUpdateWithdrawStatus() {
  return useMutation(
    (data) =>
      api
        .put(Endpoints.Admin.UpdateWithdrawStatus, data)
        .then((response) => response?.data),
    {
      onSuccess: (data) => {
        queryClient.refetchQueries(Endpoints.Admin.GetWithdrawalRequests);
      },
      onError: (error) => {
        setToast(error.response?.data?.message || unknown_Error, "error");
        reportError(
          "useUpdateWithdrawStatus: useMutation: onError: error",
          error
        );
      },
    }
  );
}

export function useGetSellerRejectReasonDropdown() {
  return useQuery(
    Endpoints.Admin.GetWithdrawRejectReason,
    () =>
      api
        .get(Endpoints.Admin.GetSellerRejectReasonDropdown)
        .then((response) => response?.data?.result),
    {
      onError: (error) => {
        reportError(
          "GetSellerRejectReasonDropdown: useQuery: onError: error",
          error
        );
      },
    }
  );
}
