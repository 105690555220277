import React, { useState, useEffect, useRef } from "react";
import { Controller, useFieldArray, useFormContext, useWatch, useForm, FormProvider } from "react-hook-form";
import { useGetVideoStoryDropdown, useCreateUgcCampaign } from "queries/campaign";
import styles from "./index.module.css";
import Input from "components/FormComponents/ControlledInput";
import Select from "components/FormComponents/ControlledSelect";
import SixtySelect from "components/SixtySelect";
import Checkbox from "components/SixtyCheckBox";
import ImageDropzone from "components/FormComponents/ControlledImageDropzone";
import { ReactComponent as IconSingleImageDrop } from "assets/icons/Icon-SingleImageDrop.svg";
import { ReactComponent as CloseIcon } from "assets/icons/IconClose.svg";
import Button from "components/SixtyButton";
import Confirmation from "permissions/Confirmation";
import Modal from "components/Modal";
import { setToast } from "components/Toast";
import ControlledDatepicker from "components/FormComponents/ControlledDatepicker";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Card from "../../../PricingCard";
import clsx from "clsx";
import ControlledChipInput from "components/FormComponents/ControlledChipInput";

const defaultValues = {
    supplierReference: '',
    supplierCode: '',
    supplierName: '',
    supplierId: '',
    name: '',
    instruction: '',
    price: 0,
    priceType: '',
    isPublic: '',
    expiredDate: '',
    defaultCampaignProducts: [],
    campaignProducts: [],
    campaignSellers: []
 };

 const scenes = [
    {
      value: 'Tutorials',
      label: 'Tutorials',
    },
    {
      value: 'how to guides',
      label: 'How to guides',
    },
    {
      value: 'Tips and tricks',
      label: 'Tips and tricks',
    },
    {
      value: 'Explainer videos',
      label: 'Explainer videos',
    },
  ];

  const pricing = [
    {
      cashSaving: 'MYR 4560',
      unitPrice: 'MYR 119',
      quantity: 12,
      price: 'MYR 1428',
      total: 1428,
      value: 'MYR 5988',
    },
    {
      cashSaving: 'MYR 12033',
      unitPrice: 'MYR 89',
      quantity: 30,
      price: 'MYR 2670',
      total: 2670,
      value: 'MYR 14970',
    },
  ];

const CreateForm = ({templateId = null, adsCampaign = false}) => {

    const [isComfirmationOpen, setComfirmationOpen] = useState(false);
    const [videoStory, setVideoStory] = useState(templateId);
    const [race, setRace] = useState(null);
    const [noOfRecruitment, setNoOfRecruitment] = useState("1");
    const [gender, setGender] = useState(null);
    const [objective, setObjective] = useState("VideoCreation");
    const [_shipmentType, setShipmentType] = useState('None');
    const [videoEditing, setVideoEditing] = useState(true);
    const [adsCopyright, setAdsCopyright] = useState(adsCampaign);
    const [ownership, setOwnership] = useState(false);
    const [hideScript, setHideScript] = useState(true);
    const [selectedPricing, setPricing] = useState(null);
    const [isCustomStory, setCustomStory] = useState(false);

    const initialBundles  = [];
    const [selectedBundle, setBundle] = useState(initialBundles);

    const initialBundlePricing  = [];
    const [selectedBundlePricing, setBundlePricing] = useState(initialBundlePricing);

    const initialStoryScenes  = [];
    const [selectedStoryScenes, setStoryScenes] = useState(initialStoryScenes);

    const { control, watch, setValue, errors } = useFormContext();
    const openDropzone = useRef(() => {});
    const comfirmationRef = useRef();

    const mainImage = useWatch({
        control,
        name: "mainImages",
        defaultValue: [],
    });

    const onMainImageDelete = () => {
        setValue("mainImages", []);
    };

    const onDefaultRawFile = () => {
        setToast("Raw File is minimum requirement", "error");
    };
    
    const ConfirmationTitle = () => {
        return (
          <div className={styles.modalTitleBar}>
            <div className={styles.modalContainer}>
              <div className={styles.modalTitle}>{'Confirmation'}</div>
            </div>
            <div>
              <CloseIcon
                className={styles.closeIcon}
                onClick={() => setComfirmationOpen(false)}
              />
            </div>
          </div>
        );
      };

    const { data: videoStories } = useGetVideoStoryDropdown();
 
    const videoStoryOptions = videoStories?.length
    ? videoStories.map((item) => {
        return { label: item.name, value: item.id };
      })
    : [];

    const onChangeStory = async (value) => {
        setVideoStory(value);

       // var script = control.getValues('tasks');

        // if(script){
        //     setComfirmationOpen(true);
        //     return;
        // }
        // else{
        //     videoStories.map((item) => {
        //         if(value === item.id){
        //             if(item.instruction){
        //                 setValue("tasks", item.instruction);
        //             }
        //             else{
        //                 setValue("tasks", "");
        //             }
                    
        //         }
        //     })
        // } 
     };
   
     const onCloseStory = () => {
       setComfirmationOpen(false);
     };

     const updateBundlePricing = () => {
        videoStories.map((item) => {
            if(videoStory === item.id){
               var currentBundle = [
                   {
                     cashSaving: `MYR ${item.bundleSaving1}` ,
                     unitPrice: `MYR ${item.bundleUnitPrice1}` ,
                     quantity: item.bundleQuantity1,
                     price: `MYR ${item.bundlePrice1}` ,
                     total: item.bundleUnitPrice1,
                     value: `MYR ${item.bundleValue1}`,
                   },
                   {
                      cashSaving: `MYR ${item.bundleSaving2}` ,
                      unitPrice: `MYR ${item.bundleUnitPrice2}` ,
                      quantity: item.bundleQuantity2,
                      price: `MYR ${item.bundlePrice2}` ,
                      total: item.bundleUnitPrice2,
                      value: `MYR ${item.bundleValue2}`,
                   },
               ];
  
               setBundle([...currentBundle]);
               setPricing(currentBundle[0]);  
               setCustomStory(item.isCustomStory);

               var currentBundlePricing = [
                   {
                     value: item.bundleQuantity1,
                     label: `${item.bundleQuantity1} videos` 
                   },
                   {
                      value: item.bundleQuantity2,
                      label: `${item.bundleQuantity2} videos`,
                   },
               ];

               setBundlePricing([...currentBundlePricing]);
   
               var storySceneOptions = item.videoScenes?.length
               ? item.videoScenes.map((scene) => {
                   return { label: scene.name, value: scene.id };
                 })
               : [];

               setStoryScenes([...storySceneOptions]);
 
            }
        })
     }

    const updatePricing = () => {
   
        // temp solutions
        // var rawFilePrice = 60;   
        // var videoEditingPrice = 60;
        // var adsCopyrightPrice = 80;
        // var ownershipPrice = 120;

        // var total = rawFilePrice + 0;

        // if(videoEditing){
        //     total = total + videoEditingPrice;
        // }

        // if(adsCopyright){
        //     total = total + adsCopyrightPrice;
        // }

        // if(ownership){
        //     total = total + ownershipPrice;
        // }

        var total = selectedPricing?.total;
   
        setValue("price", String(parseFloat(total).toFixed(2)));
        setValue("applicantLimit", selectedPricing?.quantity);
    }
  
    const updateStoryScript = () => {
        if(videoStory){
            videoStories.map((item) => {
                if(videoStory === item.id){
                    if(item.instruction){
                        //setValue("tasks", item.instruction);
                        setHideScript(true);
                    }
                    else{
                       // setValue("tasks", "");
                       setHideScript(false);
                    }
                    
                }
            })
        } 
    }

    const valuetext = (value) => {
        return `${value}`;
    }

    const handleBudgetChange = (event, newValue) => {
   
        selectedBundle.map((item) => {
            if(newValue === item.quantity){
                setPricing(item);           
            }
        })
    };

     useEffect(() => {

        if(selectedBundle.length <= 0 && videoStories.length > 0){
            
            updateBundlePricing();
        }

        updatePricing();
        setValue("isRequiredVideoEditing", videoEditing);
        setValue("isAdsUsage", adsCopyright);
        setValue("isRequiredOwnership", ownership);
     }, [videoEditing, adsCopyright, ownership, selectedPricing, videoStories]);

    return (
        <div>
                <div className={styles.form}>
                {/* <div style={{height:15}} ></div>
                <div className={styles.section}>Let us know your product or services</div>
                <hr className={styles.hr} />
                <ImageDropzone
                    control={control}
                    error={errors.mainImages}
                    name="mainImages"
                    title="Drag your product or service image here"
                    subTitle="or Click to browse for a file"
                    DefaultThumb={IconSingleImageDrop}
                    maximumFiles={1}
                    onDropAccepted={(name, selectedFiles) => {
                        setValue("mainImages", selectedFiles);
                    }}
                    setOpenDropzonehook={(open) => {
                        openDropzone.current = open;
                    }}
                    selectedFiles={mainImage}
                />
                {mainImage.length > 0 && (
                        <div className={styles.actionButtonsContainer}>
                        <div>
                            <Button
                            onClick={() => openDropzone.current()}
                            variant="secondary"
                            >
                            Replace
                            </Button>
                        </div>
                        <div>
                            <Button onClick={onMainImageDelete} variant="secondary">
                            Delete
                            </Button>
                        </div>
                        </div>
                )} */}
                {/* <div style={{height:15}} ></div>
                <div className={styles.section}>Order Options</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                <Select
                        name="objective"
                        error={errors.target}
                        options={[
                        {
                            label: "Video Creation (UGC)",
                            selectedLabel: "Video Creation (UGC)",
                            value: "VideoCreation",
                        },
                        {
                            label: "Promote on creator social media (Coming Soon)",
                            selectedLabel: "Promote on creator social media (Coming Soon)",
                            value: "Posting",
                            disabled: true
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="Objective"
                        handleChange={(name, value) => {
                            setObjective(value);
                        }}
                        isOptionDisabled={(option) =>
                            option.disabled
                        }
                        value={objective}
                        defaultValue={objective}
                    />
                </div>  
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Creator create creative video content for your products, you may use the video for own social media post, ads and so on. </div>
                </div> */}
                {!isCustomStory && (
                    <>
                        <div style={{height:15}} ></div>
                        <div className={styles.section}>Your preferred template</div>
                        <hr className={styles.hr} />
                        <div className={styles.fullFormRow}>
                            <Select
                            name="videoStory"
                            error={errors.videoStory}
                            options={videoStoryOptions}
                            control={control}
                            fullWidth
                            defaultOption="Select your template"
                            handleChange={(name, value) => {         
                                onChangeStory(value);
                            }}
                            value={videoStory}
                            defaultValue={videoStory}
                            disabled={true}
                            />
                        </div>

                        <div style={{height:15}} ></div>
                        <div className={styles.section}>Do you have any preferred scenes for your videos?

                            <a href="https://60sec.asia/video-templates/" target="_blank">  Learn More</a>
                        </div>
                        <hr className={styles.hr} />
                        <div className={styles.fullFormRow}>
                            <ControlledChipInput
                                name="videoScenes"
                                control={control}
                                error={errors.videoScenes}
                                label="Customized your video scene"
                                fullWidth
                                defaultValue={[]}
                                helperText="Customized your video with the scene"
                                handleChange={(value, options) =>          
                                    value.map((id) => {
                                    return { sceneId: id.value, sceneName: id.label};
                                    })
                                }
                                getValue={(value) => 
                                    value?.map((item) => { return {value: item.sceneId, label: item.sceneName}})
                                }
                                options={selectedStoryScenes}
                                optionsLoading={false}
                                isMultiSelect={true}
                            />  
                        </div>  
                        <div className={styles.fullFormRow}>
                            <div className={styles.section}>* Don't worries with the selection, our team will consult you once order is take. </div>
                        </div>

                        {videoStory && selectedBundlePricing.length > 0 && (
                        <>
                            <div style={{height:15}} ></div>
                            <div className={styles.section}>Select your bundle</div>
                            <hr className={styles.hr} />
                            <div className={styles.fullFormRow}>
                            {/* <Slider
                                aria-label="Restricted values"
                                getAriaValueText={valuetext}
                                defaultValue={selectedBundlePricing? selectedBundlePricing[0]?.value : 0}
                                step={null}
                                max={selectedBundlePricing? selectedBundlePricing[1]?.value + 1000 : 5000}
                                valueLabelDisplay="on"
                                marks={selectedBundlePricing}
                                onChange={handleBudgetChange}
                            /> */}
                            <Slider
                                aria-label="Restricted values"
                                getAriaValueText={valuetext}
                                valueLabelFormat={valuetext}
                                defaultValue={selectedBundlePricing? selectedBundlePricing[0]?.value : 0}
                                step={null}
                                max={selectedBundlePricing? selectedBundlePricing[1]?.value + 10 : 20}
                                valueLabelDisplay="on"
                                marks={selectedBundlePricing}
                                onChange={handleBudgetChange}
                            />
                            </div>  
                    
                            <div className={styles.fullFormRow}>
                                <Card
                                    title={'Number of Content Creator'}
                                    unit={'1'}
                                    desc={'Dedicated content creator who handle your contents'}
                                    clickEvent={onDefaultRawFile}
                                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                                />
                                <Card
                                    title={'Number of Videos'}
                                    unit={selectedPricing?.quantity}
                                    desc={'Number of videos you can get.'}
                                    clickEvent={onDefaultRawFile}
                                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                                />
                                <Card
                                    title={'Price per Video'}
                                    unit={selectedPricing?.unitPrice}
                                    desc={'More orders, more discounts.'}
                                    clickEvent={onDefaultRawFile}
                                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                                />
                                <Card
                                    title={'Total Amount'}
                                    unit={selectedPricing?.price}
                                    desc={'Total Amount you need to top-up'}
                                    clickEvent={onDefaultRawFile}
                                    className={clsx(styles.sellersGeneralReport, styles.totalApplied)}
                                />
                            </div>    
                            <div className={styles.fullFormRow}>
                                <div className={styles.section}>* Our team will contact you shortly once order is created and based on provided information advise you suitable script/story for video creation. </div>
                            </div>                
                        </>
                        )}
                    </>
                )}



                {/* <div style={{height:15}} ></div>
                <div className={styles.section}>Quantity of videos you want to order</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                    <Select
                        name="applicantLimit"
                        error={errors.target}
                        options={[
                        {
                            label: "1",
                            selectedLabel: "1",
                            value: "1",
                        },
                        {
                            label: "2",
                            selectedLabel: "2",
                            value: "2",
                        },
                        {
                            label: "3",
                            selectedLabel: "3",
                            value: "3",
                        },
                        {
                            label: "4",
                            selectedLabel: "4",
                            value: "4",
                        },
                        {
                            label: "5",
                            selectedLabel: "5",
                            value: "5",
                        },
                        {
                            label: "6",
                            selectedLabel: "6",
                            value: "6",
                        },
                        {
                            label: "7",
                            selectedLabel: "7",
                            value: "7",
                        },
                        {
                            label: "8",
                            selectedLabel: "8",
                            value: "8",
                        },
                        {
                            label: "9",
                            selectedLabel: "9",
                            value: "9",
                        },
                        {
                            label: "10",
                            selectedLabel: "10",
                            value: "10",
                        },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="No of creators to be recruit"
                        handleChange={(name, value) => {
                            setNoOfRecruitment(value);
                        }}
                        value={noOfRecruitment}
                        defaultValue={noOfRecruitment}
                    />
                </div>      */}
    
            
   

                {/* <div hidden={hideScript}>
                        <div style={{height:15}} ></div>
                        <div className={styles.section}>Script / story for creators</div>
                        <hr className={styles.hr} />
                        <div className={styles.fullFormRow}>
                            <Input
                            name="tasks"
                            label="Story"
                            lines={24}
                            defaultValue={''}
                            />
                        </div>
                </div> */}
                {isCustomStory && (
                    <>
                        <div style={{height:15}} ></div>
                        <div className={styles.section}>{!isCustomStory ? 'Your selected bundle' : 'Let us know your budget'}</div>
                        <hr className={styles.hr} />               
                    </>
                )}

                <div className={styles.formRow}>
                {/* <div className={styles.section}>Raw File      : MYR  60</div>
                <div className={styles.section}>Video Editing : MYR  60</div>
                <div className={styles.section}>Ads Purpose : MYR  80</div> */}
                {/* <div className={styles.section}>Ownership     : MYR 120</div> */}
    
                </div>

                <div className={styles.fullFormRow}>
                    <Input
                        name="price"
                        error={errors.price}
                        control={control}
                        label={!isCustomStory ? 'Total' : 'Your budget'}
                        type="number"
                        min={0}
                        step=".01"
                        defaultValue={100}
                        disabled={!isCustomStory ? true : false}
                        className={!isCustomStory ? styles.hidden : ''}
                    />
                    <Input
                        name="applicantLimit"
                        error={errors.applicantLimit}
                        control={control}
                        label={'Quantity'}
                        type="number"
                        min={0}
                        step=".01"
                        defaultValue={1}
                        disabled={true}
                        className={styles.hidden}
                    />
                </div> 
           
                {/* <div style={{height:15}} ></div>
                <div className={styles.fullFormRow}>
                    <Checkbox
                     label="Raw File"
                     checked={true}
                     onChange={(e) => onDefaultRawFile()}
                     uncheckStyleVariant="greyedOut"
                    />
                </div>
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Raw video file without any editing or effect</div>
                </div>

                <div className={styles.fullFormRow}>
                    <Checkbox
                    label="Video Editing"
                    checked={videoEditing}
                    onChange={(e) => setVideoEditing(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                    />
                    <Input
                    name={`isRequiredVideoEditing`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={videoEditing}
                    value={videoEditing}
                    className={styles.hidden}
                    />
                </div>

                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Add-ons video editing effect to your raw video e.g background music, voice over, text to become more interesting.</div>
                </div>

                <div className={styles.fullFormRow}>
                    <Checkbox
                    label="Use For Ads"
                    checked={adsCopyright}
                    onChange={(e) => setAdsCopyright(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                    />
                    <Input
                    name={`isAdsUsage`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={adsCopyright}
                    value={adsCopyright}
                    className={styles.hidden}
                    />
                </div>

                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Video for ads purpose </div>
                </div> */}

                {/* <div className={styles.fullFormRow}>
                    <Checkbox
                    label="Content Ownership"
                    checked={ownership}
                    onChange={(e) => setOwnership(e.target.checked)}
                    uncheckStyleVariant="greyedOut"
                    />
                    <Input
                    name={`isRequiredOwnership`}
                    control={control}
                    type="hidden"
                    label="Visibility"
                    defaultValue={ownership}
                    value={ownership}
                    className={styles.hidden}
                    />
                </div>

                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* You can own the video content for branding purpose e.g website, offline shop</div>
                </div> */}

    
                {/* {videoStory && (
                    <div className={styles.fullFormRow}>
                        <div className={styles.section}>* Feel free to edit the below script template with your own product information.</div>
                    </div>
                )}

                <div style={{height:15}} ></div>
                <div className={styles.section}>Script for creators</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                    <Input
                    name="tasks"
                    label="Tasks"
                    lines={24}
                    defaultValue={''}
                    />
                </div> */}

                <div style={{height:15}} ></div>
                <div className={styles.section}>Your website or social media</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                  <Input
                    name="name"
                    label="Website e.g https://60sec.asia/"
                    defaultValue=""
                  />
                </div>  

                <div style={{height:15}} ></div>
                <div className={styles.section}>Let us know your requirements</div>
                <hr className={styles.hr} />
   
                <div className={styles.fullFormRow}>
                    <Input
                    name="instruction"
                    label="Requirement"
                    lines={8}
                    defaultValue={''}
                    />
                </div>

                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Your requirement will allow us know more about you and propose right content.</div>
                </div>
                <div style={{height:15}} ></div>
                <div className={styles.section}>Notifications</div>
                <hr className={styles.hr} />
                <div className={styles.formRow}>
                  <Input
                    name="picContact"
                    label="Contact number e.g 60145645678"
                    defaultValue=""
                  />
                </div>  
                <div className={styles.fullFormRow}>
                    <div className={styles.section}>* Our team or creator will contact you through above number.</div>
                </div>

                <div style={{height:15}} ></div>
                <div className={styles.section}>Do you have any sample product for our creators?</div>
                <hr className={styles.hr} />
                <div className={styles.fullFormRow}>
                <Select
                        name="shipmentType"
                        error={errors.target}
                        options={[
                        {
                            label: "No",
                            selectedLabel: "No",
                            value: "None",
                        },
                        {
                            label: "Yes",
                            selectedLabel: "Yes",
                            value: "Manual",
                        },
                        // {
                        //     label: "Voucher Code (Coming Soon)",
                        //     selectedLabel: "Voucher Code (Coming Soon)",
                        //     value: "VoucherCode",
                        //     disabled: true
                        // },
                        ]}
                        control={control}
                        fullWidth
                        defaultOption="Shipment Options"
                        handleChange={(name, value) => {
                            setShipmentType(value);
                        }}
                        value={_shipmentType}
                        defaultValue={_shipmentType}
                    />
                </div>  
       
                {isComfirmationOpen && (
                    <Modal
                    isOpen={isComfirmationOpen}
                    onAttemptClose={() => {
                        setComfirmationOpen(false);
                    }}
                    modalRef={comfirmationRef}
                    Title={ConfirmationTitle}
                    >
                    <Confirmation
                        title={'Your scripts will be overwrite.'}
                        handleSuccess={updateStoryScript}
                        handleClose={onCloseStory}
                    />
                    </Modal>
                )}
                </div>                          
    </div>
  );
};

export default CreateForm;
